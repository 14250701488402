<template lang="html">
  <article
    v-if="!loading && data"
    class="card card--big"
  >
    <div class="card__body font-centered">
      <h3 class="card__title font-size-tiny text-upper w-extrabold">
        {{ title }}
      </h3>
      <p
        v-if="isDatoDecimal"
        class="card__meta font-size-big"
      >
        {{ datoFormatDecimal }}
      </p>
      <p
        v-else
        class="card__meta font-size-big"
      >
        {{ datoFormatMilesimal }}
      </p>
      <div class="card__data">
        <div
          v-for="compare in data[consulta].comparacion"
          :key="compare"
          class="card__data__item"
        >
          <h4 class="card__title font-size-short">
            {{ compare.titulo }}
          </h4>
          <p
            v-if="stringNumberIsDecimal(compare.dato)"
            class="card__meta font-size-tiny"
          >
            {{ parseFloat(compare.dato).toFixed(2) }}
          </p>
          <p
            v-else
            class="card__meta font-size-tiny"
          >
            {{ formatStringNumberToMilesimal(compare.dato) }}
          </p>

          <p
            v-if="compare.incremento >= 0"
            class="card__meta font-size-tiny cypher-up"
          >
            {{ parseFloat(compare.incremento).toFixed(2) }}%
          </p>
          <p
            v-if="compare.incremento < 0"
            class="card__meta font-size-tiny cypher-down"
          >
            {{ parseFloat(compare.incremento).toFixed(2) }}%
          </p>
        </div>
      </div>
    </div>
  </article>
  <CardSkeleton v-else />
</template>

<script>
// Mantener esa config desactivada por un bug con Vue 3
/* eslint-disable vue/no-dupe-keys */

import { useStore } from 'vuex';
import { onMounted, watch, ref, toRefs, computed } from 'vue';
//Libs
import axios from 'axios';
import dataPersistence from '../services/dataPersistence';
//Utils
import { stringNumberIsDecimal, formatStringNumberToMilesimal } from '../utils';
//Components
import CardSkeleton from './Skeletons/CardSkeleton';

export default {
  components: {
    CardSkeleton,
  },
  props: {
    name: String,
    metodo: String,
    title: String,
    source: String,
    dataSource: String,
    metricsTag: String
  },
  setup(props) {
    const store = useStore();
    // Props
    const { name, metodo, title, source, metricsTag, dataSource } = toRefs(props);

    // States
    const keyStorage = `${title.value.split(' ').join('-')}-${metodo.value}-${
      name.value
    }`;
    const URL = `${source.value}/${dataSource.value}/${metodo.value}/${name.value}/`;
    const data = ref(dataPersistence.getItem(keyStorage) || null);
    const loading = ref(false);
    const consulta = ref(data.value ? data.value.consulta[0] : '');

    // Computed
    const fechaReporte = computed(() => store.getters.getFechaReporte);

    const isDatoDecimal = computed(() => {
      return stringNumberIsDecimal(data.value[consulta.value].dato);
    });
    const datoFormatDecimal = computed(() => {
      return parseFloat(data.value[consulta.value].dato).toFixed(2);
    });
    const datoFormatMilesimal = computed(() => {
      return formatStringNumberToMilesimal(data.value[consulta.value].dato);
    });

    // Methods
    const fetchData = () => {
      axios
        .post(URL, {...fechaReporte.value, metricsTag: metricsTag.value, titulo: title.value})
        .then((response) => {
          data.value = response.data;
          dataPersistence.setItem(keyStorage, response.data);
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    // Mounted
    onMounted(() => {
      if (!data.value) {
        loading.value = true;
        fetchData();
      }
    });

    // Watcher's
    watch(fechaReporte, () => {
      loading.value = true;
      fetchData();
    });

    watch(data, () => {
      consulta.value = data.value.consulta[0];
    });

    return {
      data,
      title,
      consulta,
      loading,
      isDatoDecimal,
      stringNumberIsDecimal,
      formatStringNumberToMilesimal,
      datoFormatDecimal,
      datoFormatMilesimal,
    };
  },
};
</script>

<style lang="scss" scoped>
.loading {
  h3,
  h4,
  p {
    color: #e3e3e3 !important;
    background: #e3e3e3 !important;
    border-radius: 5px !important;
  }
}
.gr-3.card--big {
  max-width: 25%;
}
.puntajes{
  flex-basis: 25%; 
  width: 25%; 
  max-width: 25%;
}
</style>
