import { firebase } from '@firebase/app';
import 'firebase/app';
import 'firebase/auth';
import store from '../store';

// Plantear el uso de Variables de entornos - Seguridad
const FIREBASE_CONFIG = {
  apiKey: "AIzaSyAayIiPEm5DiWli8APWBHe5NmsLP6y2naA",
  authDomain: "reportes-santotomas.firebaseapp.com",
  databaseURL: "https://reportes-santotomas.firebaseio.com",
  projectId: "reportes-santotomas",
  storageBucket: "reportes-santotomas.appspot.com",
  messagingSenderId: "1025718145833",
  appId: "1:1025718145833:web:0ef32b052ed82552"
};

firebase.initializeApp(FIREBASE_CONFIG)
const auth = firebase.auth();

// Behaviors
auth.onAuthStateChanged(async user => {
  let claims = null;

  await user
    .getIdTokenResult() 
    .then(idTokenResult => {
      claims = idTokenResult.claims;
    }).catch((error) => {
      console.log(error);
    });

  store.dispatch("setUser", { user, claims });
});

export {
  auth
}
