<template>
  <header-view v-if="isUserLoggedIn" />
  <menu-main v-if="isUserLoggedIn" />
  <router-view />
  <footer-view v-if="isUserLoggedIn" />
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import Header from '@/components/Header.vue'
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    'header-view': Header,
    'menu-main': Menu,
    'footer-view': Footer,
  },
  setup() {
    const store = useStore()

    // Computed
    const isUserLoggedIn = computed(() => store.getters.isUserLoggedIn)

    return {
      isUserLoggedIn,
    }
  },
}
</script>

<style lang="scss">
:root {
  --icon-size-small: 22px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.horizon__title {
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 2rem;
  line-height: 44px;
  color: #333333;
}

.spacer {
  flex-grow: 1;
}

a:hover {
  text-decoration: none;
}
</style>
