import moment from 'moment'

/**
  * Verifica si un string contiene un "."
  * @param {string} value - Cadena de caracteres a analizar
  */
const stringNumberIsDecimal = (value) => {
  return value.toString().includes('.');
};

/**
  * Formate un string de numeros al formato de miles
  * @param {string} value - Cadena de caracteres a formatear
  */
const formatStringNumberToMilesimal = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

/**
  * Retrasa la ejecucion de una accion.
  * @param {string} time - Tiempo en milisegundos
  */
function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––-
// Utilidad para definir el valor por default del componente fechaReporte
// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––-

let periodoActualDesde = moment().startOf('month').format('YYYY-MM-DD');
let periodoActualHasta = moment().format('YYYY-MM-DD');
let periodoAnteriorDesde = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
let periodoAnteriorHasta = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
let annoAnteriorDesde = moment().subtract(1, 'year').startOf('month').format('YYYY-MM-DD');
let annoAnteriorHasta = moment().subtract(1, 'year').endOf('month').format('YYYY-MM-DD');

let defaultDates = {
  nombre: 'hoy',
  periodo: [
    { desde: periodoActualDesde, hasta: periodoActualHasta },
    { desde: periodoAnteriorDesde, hasta: periodoAnteriorHasta },
    { desde: annoAnteriorDesde, hasta: annoAnteriorHasta },
  ],
};


const SEDES_ST = [
  'Antofagasta',
  'Arica',
  'Chillán',
  'Concepción',
  'Copiapó',
  'Curicó',
  'Estación Central',
  'Iquique',
  'La Serena',
  'Los Ángeles',
  'Osorno',
  'Ovalle',
  'Puente Alto',
  'Puerto Montt',
  'Punta Arenas',
  'Rancagua',
  'San Joaquín',
  'Santiago Centro',
  'Santiago UST',
  'Talca',
  'Temuco',
  'Valdivia',
  'Viña del Mar',
]

const CURSOS_ST = [
  '4º Medio',
  '3º Medio',
  '2º medio',
  '1º medio',
  'egresado',
]

const BECAS_ST = {
  'Beca Juan Gómez Millás': 'Beca Juan Gómez Millás',
  'Beca Excelencia Académica': 'Beca Excelencia Académica',
  'Beca Nuevo Milenio II': 'Beca Nuevo Milenio II',
  'Beca Nuevo Milenio I': 'Beca Nuevo Milenio I'
}

const range = (start, end) => {
  const length = end - start;
  return Array.from({ length }, (_, i) => start + i);
}


export {
  stringNumberIsDecimal,
  formatStringNumberToMilesimal,
  sleep,
  range,
  defaultDates,
  SEDES_ST,
  CURSOS_ST,
  BECAS_ST
}