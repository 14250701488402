// El proposito de la abstracción de este servicio
// es en un futuro integrar procesos de seguridad (hash, entro otros)
// para evitar el guardado de informacion posiblemente 
// sensible en el localStorage.


export default class dataPersistence {

  constructor() {}

  /**
  * Obtener un item del localStorage.
  * @param {string} itemName - Nombre de la colección.
  */
  static getItem(itemName) {
    const item = localStorage.getItem(itemName);
    const rgxNum = new RegExp(/^\d+$/);
    const rgxJson = new RegExp(/[[{].*[}\]]/);

    if(item){
      if(rgxJson.test(item)){
        return JSON.parse(item);
      }
      else if(rgxNum.test(item)) {
        return parseFloat(item);
      }
      else {
        return item;
      }
    } else {
      return null;
    }
  }

  /**
  * Guardar un item en el localStorage.
  * @param {string} itemName - Nombre de la colección.
  * @param {Object} data - Colección.
  */
  static setItem(itemName, data) {
    if(typeof data === "object"){
        localStorage.setItem(itemName, JSON.stringify(data));
      } else {
        localStorage.setItem(itemName, data);
      } 
  }

  /**
  * Eliminar un item de el localStorage.
  * @param {string} itemName - Nombre de la colección.
  */
  static removeItem(itemName) {
    localStorage.removeItem(itemName);
  }

  /**
  * Limpia el localStorage
  */
  static clean() {
    localStorage.clear();
  }



}