<template lang="html">
  <div class="container">
    <span
      v-if="showPActual"
      class="upper-date upper-date--main"
    >
      Período actual: {{ periodoActualDesde }} a {{ periodoActualHasta }}
    </span>
    <span
      v-if="showPAnterior"
      class="separator"
    >|</span>
    <small
      v-if="showPAnterior"
      class="upper-date upper-date--secondary"
    >
      Período ant.: {{ periodoAnteriorDesde }} a {{ periodoAnteriorHasta }}
    </small>
    <span
      v-if="showAnnoAnterior"
      class="separator"
    >|</span>
    <small
      v-if="showAnnoAnterior"
      class="upper-date upper-date--secondary upper-date--old"
    >
      Año ant.: {{ annoAnteriorDesde }} a {{ annoAnteriorHasta }}
    </small>
  </div>
</template>
<script>
// No eliminar esta regla
/* eslint-disable vue/no-dupe-keys */

import { computed, toRefs } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'PeriodoReporte',
  props: {
    showPActual: {
      type: Boolean,
      default: true,
    },
    showPAnterior: {
      type: Boolean,
      default: true,
    },
    showAnnoAnterior: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { showPActual, showPAnterior, showAnnoAnterior } = toRefs(props)
    const store = useStore()
    const periodoActualDesde = computed(() => store.getters.getPeriodoReporte[0]['desde'])
    const periodoActualHasta = computed(() => store.getters.getPeriodoReporte[0]['hasta'])
    const periodoAnteriorDesde = computed(() => store.getters.getPeriodoReporte[1]['desde'])
    const periodoAnteriorHasta = computed(() => store.getters.getPeriodoReporte[1]['hasta'])
    const annoAnteriorDesde = computed(() => store.getters.getPeriodoReporte[2]['desde'])
    const annoAnteriorHasta = computed(() => store.getters.getPeriodoReporte[2]['hasta'])

    return {
      showPActual,
      showPAnterior,
      showAnnoAnterior,
      periodoActualDesde,
      periodoActualHasta,
      periodoAnteriorDesde,
      periodoAnteriorHasta,
      annoAnteriorDesde,
      annoAnteriorHasta,
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-flow: nowrap;
  justify-content: center;
  align-items: center;
}

.separator {
  padding: 0.5rem;
}

small {
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  color: #333333;
  margin-left: 0;
}
</style>
