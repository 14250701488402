<template>
  <article class="card card--big loading">
    <div class="card__body font-centered">
      <h3 class="card__title font-size-tiny text-upper w-extrabold">
        Sin título
      </h3>
      <p class="card__meta font-size-big">
        000
      </p>
      <div class="card__data">
        <div class="card__data__item">
          <h4 class="card__title font-size-short">
            Sin título
          </h4>
          <p class="card__meta font-size-tiny">
            000
          </p>
          <p class="card__meta font-size-tiny cypher-up">
            000
          </p>
        </div>
        <div class="card__data__item">
          <h4 class="card__title font-size-short">
            Sin título
          </h4>
          <p class="card__meta font-size-tiny">
            000
          </p>
          <p class="card__meta font-size-tiny cypher-up">
            000
          </p>
        </div>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
  .loading {
    h3,
    h4,
    p {
      color: #e3e3e3 !important;
      background: #e3e3e3 !important;
      border-radius: 5px !important;
    }
  }
  .gr-3.card--big {
    max-width: 25%;
  }
</style>
