import { createStore } from 'vuex';
import { defaultDates } from '../utils'
import dataPersistence from '../services/dataPersistence'
require('dotenv').config();

//https://api.idastage.com/api/dashboard/
//http://localhost:4000/api/dashboard/// 2021-09-07T12:35:12+00:00 
// http://admision.qa.santotomas.cl/ No usar a menos que sea HTTPS
// https://www.tupuedes.cl/wp-json/

const store = createStore({
  state() {

    return {
      reporteFecha: dataPersistence.getItem('fecha_reporte') || defaultDates,
      mongoURL: process.env.VUE_APP_MONGO_URL,
      analyticsWPJSONURL: process.env.VUE_APP_ANALYTICS_WPJSON_URL,
      mongoFeriasURL: process.env.VUE_APP_MONGO_FERIAS_URL,
      produccionURL: process.env.VUE_APP_PRODUCTION_URL,
      analyticsURL: process.env.VUE_APP_ANALYTICS_URL,
      //Databases names
      dbDashboard: process.env.VUE_APP_DB_DASHBOARD,
      dbDashboardFerias: process.env.VUE_APP_DB_DASHBOARD_FERIAS,
      dbProduccion: process.env.VUE_APP_DB_PRODUCCION,
      user: { 
        loggedIn: false,
        data: {
          displayName: 'Sin user',
          email: 'Sin Email',
        },
        claims: {}
      },
      starredForms: dataPersistence.getItem('starred_forms') || { form: [], feria: [] }
    }
  },
  getters: {
    user: (state) => {
      return state.user;
    },
    isUserLoggedIn: (state) => {
      return state.user.loggedIn;
    },
    getUserClaims: (state) => {
      return state.user.claims;
    },
    getFechaReporte: (state) => {
      return state.reporteFecha;
    },
    getPeriodoReporte: (state) => {
      return state.reporteFecha.periodo;
    },
    getMongoURL: (state) => {
      return state.mongoURL;
    },
    getMongoFeriasURL: (state) => {
      return state.mongoFeriasURL;
    },
    getAnalyticsWPJSONURL: (state) => { 
      return state.analyticsWPJSONURL;
    },
    getProduccionURL: (state) => {
      return state.produccionURL;
    },
    getAnalyticsURL: (state) => {
      return state.analyticsURL;
    },
    getDBDashboard: (state) => {
      return state.dbDashboard;
    },
    getDBDashboardFerias: (state) => {
      return state.dbDashboardFerias;
    },
    getProduccion: (state) => {
      return state.dbProduccion;
    },
  },
  mutations: {
    addturno(state, shift) {
      state.turno = shift;
    },
    SET_USER(state, value) {
      state.user = value
    },
    SET_USER_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER_DATA(state, data) {
      state.user.data = data;
    },
    // reporte temporal
    SET_FECHA_REPORTE(state, payload) {
      state.reporteFecha = payload;
    },
    SET_PERIODO_FECHAREPORTE(state, payload) {
      state.reporteFecha.periodo = payload;
    },
    //Starred Forms
    SET_STARRED_FORM(state, payload) {
      let newValues = [...state.starredForms[payload.type], payload.name];
      state.starredForms = { ...state.starredForms, [payload.type]: newValues }
    },
    DELETE_STARRED_FORM(state, payload) {
      let tempVal = state.starredForms[payload.type].filter((current) => current != payload.name);
      state.starredForms = { ...state.starredForms, [payload.type]: tempVal }
    },
  },
  actions: {
    setUser({ commit }, { user, claims }) {
      if (user) {
        commit('SET_USER', {
          loggedIn: true,
          data: {
            displayName: user.displayName,
            email: user.email,
          },
          claims
        })
      }
    },
    setReporteFecha({ commit, getters }, payload) {
      if (payload.periodo != getters.getPeriodoReporte) {
        dataPersistence.clean();
      }
      dataPersistence.setItem('fecha_reporte', payload);
      commit('SET_FECHA_REPORTE', payload);
    },
    toggleStarredForm({ commit, state }, payload) {
      const MAX_NUM_OF_STARRED = 3;
      if (state.starredForms[payload.type].includes(payload.name)) {
        commit('DELETE_STARRED_FORM', payload)
      } else {
        if (state.starredForms[payload.type].length >= MAX_NUM_OF_STARRED) {
          alert(
            `El número máximo de formularios destacados es 3.\nElimine formularios destacados para agregar nuevos a la lista.`
          )
          return;
        }
        commit('SET_STARRED_FORM', payload);
      }
      dataPersistence.setItem('starred_forms', state.starredForms)
    },

  },
});


export default store;
