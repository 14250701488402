<template>
  <div class="horizon">
    <div class="container">
      <div class="container-datapicker">
        <div class="wrapper">
          <img
            src="~@/assets/img/iconos/calendario.png"
            alt="Icono de calendario"
          >
        </div>

        <div class="wrapper">
          <PeriodoReporte />
        </div>

        <button
          class="button"
          @click="expandInputs"
        >
          Cambiar período
          <img
            v-if="!showInputs"
            src="~@/assets/img/iconos/down-arrow.svg"
            alt="Icono flecha hacia abajo"
          >
          <img
            v-if="showInputs"
            src="~@/assets/img/iconos/close.svg"
            alt="Icono cruceta"
          >
        </button>
      </div>

      <transition name="expand">
        <div
          v-show="showInputs"
          class="datepicker"
        >
          <div class="datepicker-wrapper">
            <p>Período actual</p>
            <div class="datepicker-wrapper-inputs">
              <div class="form-control ">
                <label
                  for="desde-1"
                  class="form-control__label"
                >Desde</label>
                <input
                  id="desde-1"
                  v-model="periodoActualDesde"
                  type="date"
                  class="form-control__field"
                  data-datepicker
                  name="desde"
                  placeholder="Desde"
                  required
                >
              </div>

              <div class="form-control ">
                <label
                  for="hasta-1"
                  class="form-control__label"
                >Hasta</label>
                <input
                  id="hasta-1"
                  v-model="periodoActualHasta"
                  type="date"
                  class="form-control__field"
                  data-datepicker
                  name="hasta"
                  placeholder="Hasta"
                  required
                >
              </div>
            </div>
          </div>

          <div class="datepicker-wrapper">
            <p>Período anterior</p>
            <div class="datepicker-wrapper-inputs">
              <div class="form-control">
                <label
                  for="desde-2"
                  class="form-control__label"
                >Desde</label>
                <input
                  id="desde-2"
                  v-model="periodoAnteriorDesde"
                  type="date"
                  class="form-control__field"
                  data-datepicker
                  name="desde"
                  placeholder="Desde"
                  required
                >
              </div>
              <div class="form-control">
                <label
                  for="hasta-2"
                  class="form-control__label"
                >Hasta</label>
                <input
                  id="hasta-2"
                  v-model="periodoAnteriorHasta"
                  type="date"
                  class="form-control__field"
                  data-datepicker
                  name="hasta"
                  placeholder="Hasta"
                  required
                >
              </div>
            </div>
          </div>
          <div class="datepicker-wrapper">
            <p>Año anterior</p>
            <div class="datepicker-wrapper-inputs">
              <div class="form-control ">
                <label
                  for="desde-3"
                  class="form-control__label"
                >Desde</label>
                <input
                  id="desde-3"
                  v-model="annoAnteriorDesde"
                  type="date"
                  class="form-control__field"
                  data-datepicker
                  name="desde"
                  placeholder="Desde"
                  required
                >
              </div>
              <div class="form-control">
                <label
                  for="hasta-3"
                  class="form-control__label"
                >Hasta</label>
                <input
                  id="hasta-3"
                  v-model="annoAnteriorHasta"
                  type="date"
                  class="form-control__field"
                  data-datepicker
                  name="hasta"
                  placeholder="Hasta"
                  required
                >
              </div>
            </div>
          </div>
          <button
            class="button"
            @click="generar_reporte"
          >
            Generar reporte
          </button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import PeriodoReporte from '@/components/PeriodoReporte.vue'

export default {
  components: {
    PeriodoReporte,
  },
  setup() {
    const store = useStore()

    // States
    const showInputs = ref(false)
    const nombreReporte = ref('hoy')
    const periodoActualDesde = ref(store.getters.getPeriodoReporte[0]['desde'])
    const periodoActualHasta = ref(store.getters.getPeriodoReporte[0]['hasta'])
    const periodoAnteriorDesde = ref(store.getters.getPeriodoReporte[1]['desde'])
    const periodoAnteriorHasta = ref(store.getters.getPeriodoReporte[1]['hasta'])
    const annoAnteriorDesde = ref(store.getters.getPeriodoReporte[2]['desde'])
    const annoAnteriorHasta = ref(store.getters.getPeriodoReporte[2]['hasta'])

    // Methods
    const expandInputs = () => {
      showInputs.value = !showInputs.value
    }

    const generar_reporte = () => {
      const valueFechaReporte = {
        nombre: nombreReporte.value,
        periodo: [
          {
            desde: periodoActualDesde.value,
            hasta: periodoActualHasta.value,
          },
          {
            desde: periodoAnteriorDesde.value,
            hasta: periodoAnteriorHasta.value,
          },
          {
            desde: annoAnteriorDesde.value,
            hasta: annoAnteriorHasta.value,
          },
        ],
      }
      store.dispatch('setReporteFecha', valueFechaReporte)
    }

    return {
      showInputs,
      periodoActualDesde,
      periodoActualHasta,
      periodoAnteriorDesde,
      periodoAnteriorHasta,
      annoAnteriorDesde,
      annoAnteriorHasta,
      expandInputs,
      generar_reporte,
    }
  },
}
</script>

<style lang="scss" scoped>
.container-datapicker {
  display: grid;
  grid-template-columns: 1rem 1fr 18%;
  justify-items: stretch;
  background: #f3f3f3;
  border: 1px solid #cccccc;
  margin-top: 2rem;
}

.datepicker {
  width: 100%;
  background: #e5e5e5;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  padding: 1.5rem 1.5rem;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  justify-items: center;
  align-items: center;

  &-wrapper {
    background: #f3f3f3;
    border: 1px solid #cccccc;
    padding: 20px;

    &-inputs {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.5rem;
      justify-items: stretch;

      .form-controls {
        margin-bottom: 0 !important;
      }

      label {
        text-align: left;
      }

      input {
        font-size: 0.8rem;
        font-weight: 700;
        background: #ffffff;
        border: 1px solid #cccccc;
      }
    }
  }

  button {
    grid-column-start: 3;
    justify-self: end;
    padding: 1rem 1.5rem;
    background: #fea82b;
    border: 2px solid #e69827;
  }

  p {
    font-size: 0.98rem;
    font-weight: 800;
    line-height: 22px;
    color: #333333;
    text-align: left;
    padding-bottom: 4px;
  }
}

.wrapper {
  display: grid;
  place-content: center;
  padding: 0.5rem 2rem;
}

.button {
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  background: #a3b84b;
  border: 2px solid #9cb045;
  border-radius: 0;

  img {
    width: 20px;
    padding: 0.05rem;
  }
}

// Animations

.expand-enter-active {
  animation: showInputs 0.2s ease-in;
}
.expand-leave-active {
  animation: showInputs 0.2s reverse ease-out;
}

@keyframes showInputs {
  0% {
    opacity: 0;
    padding: 0rem 1.5rem;
    height: 0px;
  }
  100% {
    opacity: 1;
    padding: 1.5rem 1.5rem;
    height: 300px;
  }
}
</style>
