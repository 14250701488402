<template>
  <div class="horizon">
    <div class="container">
      <div class="separator" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.separator {
  width: 100%;
  border-bottom: 2px solid #c8c8c8;
  margin: 0.5rem 0;
}
</style>