<template>
  <header
    id="nav"
    class="header-bar"
  >
    <section
      class="nav-bar"
      data-module="nav-bar"
    >
      <div class="container">
        <div class="nav-bar__holder">
          <div class="nav-bar__mobile-head">
            <button
              class="nav-bar__mobile-menu show@tablet"
              aria-label="Ver menú"
              data-role="nav-deployer"
            >
              <span />
            </button>
            <div class="nav-bar__brand">
              <a
                class="app-brand"
                title="Ir a "
                href="/"
              >
                <h1 class="app-brand__name">Informe de métricas de Santo Tomás</h1>
              </a>
            </div>
            <button
              class="nav-bar__mobile-aux show@tablet"
              aria-label="Ver búsqueda"
              data-role="aux-deployer"
            >
              <span />
            </button>
          </div>
          <div
            v-if="user.loggedIn"
            class="nav-bar__body"
            data-role="nav-body"
          >
            <div class="nav-bar__menu-holder">
              <div class="nav-bar__tag">
                <div class="nav-bar__tag__ft">
                  Hola {{ user.data.email }} -
                  <a
                    href="#"
                    class="nav-link"
                    @click="signOut"
                  >Logout</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </header>
</template>

<script>
  import '@/dist/css/main.css'
  import { computed } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import { auth } from '../services/firebase'

  export default {
    name: 'HeaderView',
    props: {
      name: String,
      metodo: String,
      title: String,
      source: String,
    },

    setup() {
      const store = useStore()
      const router = useRouter()

      //Computed
      const user = computed(() => store.getters.user)

      // Methods
      const signOut = () => {
        auth.signOut().then(() => {
          store.commit('SET_USER_LOGGED_IN', false)
          store.commit('SET_USER_DATA', null)
          router.replace({ name: 'Login' })
        })
      }

      return {
        user,
        signOut,
      }
    },
  }
</script>

<style lang="scss">
  .menu-fechas {
    &.deployed {
      z-index: 100;
    }
  }
  .menu-comments {
    position: absolute;
    top: 180%;
    &__deployer {
      border-top: 1px solid #ffffff;
      &:before {
        content: url('../assets/img/ketchup-icons/light/icon-comment.svg');
      }
    }
    &.deployed {
      max-width: 850px;
      min-width: 850px;
      z-index: 100;
    }
  }

  .menu-informes {
    position: absolute;
    top: 260%;
    &__deployer {
      border-top: 1px solid #ffffff;
      &:before {
        content: url('../assets/img/ketchup-icons/light/icon-list.svg');
      }
    }
    &.deployed {
      max-width: 1000px;
      min-width: 850px;
      z-index: 100;
    }
  }

  .menu-pdf {
    position: absolute;
    top: 340%;
    &__deployer {
      border-top: 1px solid #ffffff;
      &:before {
        content: url('../assets/img/ketchup-icons/light/icon-pdf.svg');
      }
    }
    &.deployed {
      max-width: 1200px;
      min-width: 1200px;
      z-index: 100;
    }
  }

  .menu-dates {
    &__deployer {
      &:before {
        content: url('../assets/img/ketchup-icons/light/icon-calendar.svg');
      }
    }
  }

  .reports__list {
    overflow-y: scroll;
    max-height: 380px;
  }

  //Editor
  :host ::ng-deep .ck-editor__editable_inline {
    min-height: 500px;
    background: #fafafa;
    padding-left: 1em;
    margin: 1em 0 0 0;
  }

  :host ::ng-deep .ck-read-only {
    background-color: #ffffff;
    padding: 0;
  }

  .d-none {
    display: none;
  }

  // Section MODAL
  .section-modal {
    position: fixed;
    inset: 0;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #2423236b;
  }

  #modal-container {
    height: 100vh;
    display: grid;
    place-items: center;
  }

  .modal-content {
    width: 100%;
    max-width: 1280px;
    max-height: 720px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
</style>
