<template>
  <main>
    <section class="horizon">
      <div class="container">
        <div class="in-between">
          <h2 class="horizon__title">
            Dashboard
          </h2>
        </div>
        <div class="row">
          <div class="gr-12 card--group">
            <div class="row">
              <div class="gr-4 card--group">
                <div class="row">
                  <cards-view title="Formularios Totales" :source="mongo" :data-source="dashboardDB" metodo="count_all_formularios" name="cotizaciones" />
                  <cards-view title="Formularios incompletos" :source="mongo" :data-source="dashboardDB" metodo="formularios_incompletos" name="formularios_incompletos" />
                </div>
              </div>
              <div class="gr-4 card--group">
                <div class="row">
                  <cards-view title="Carreras" :source="mongo" :data-source="dashboardDB" metodo="count_all_carreras" name="cotizaciones" />
                  <cards-view title="1 carrera" :source="mongo" :data-source="dashboardDB" metodo="count_1_carreras" name="cotizaciones" />
                </div>
              </div>
              <div class="gr-4 card--group">
                <div class="row">
                  <cards-view title="2 carreras" :source="mongo" :data-source="dashboardDB" metodo="count_2_carreras" name="cotizaciones" />
                  <cards-view title="3 carreras" :source="mongo" :data-source="dashboardDB" metodo="count_3_carreras" name="cotizaciones" />
                </div>
              </div>
              <div class="gr-4 card--group">
                <div class="row">
                  <cards-view title="Llamados" :source="mongo" :data-source="dashboardDB" metodo="count_llamados" name="clicktocall" />
                  <cards-view title="Contactos" :source="mongo" :data-source="dashboardDB" metodo="count_contactos" name="contacto" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import CardsDoble from '@/components/CardsDoble.vue'
import CardsCuadruple from '@/components/CardsCuadruple.vue'
import Cards from '@/components/Cards.vue'

export default {
  name: 'Dashboard',
  components: {
    'cards-view': Cards,
    // 'cards-doble-view': CardsDoble,
    // 'cards-cuadruple-view': CardsCuadruple,
  },
  setup() {
    const store = useStore()
    const mongo = computed(() => store.getters.getMongoURL)
    //const analytics = computed(() => store.getters.getAnalyticsURL)

    const dashboardDB = computed(() => store.getters.getDBDashboard)
    return { mongo, dashboardDB }
  },
}
</script>


<style lang="scss" scoped>
h2 {
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 2rem;
  line-height: 44px;
  color: #333333;
}
</style>
