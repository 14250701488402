<template>
  <footer class="footer-bar">
    <div class="container font-centered">
      <p>Informe de métricas de Santo Tomás generado por IDAmetrics by IDA</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterView',
  props: {
    msg: String,
  },
};
</script>

<style lang="css" scoped>
.footer {
  padding: 10px 0;
}
</style>
